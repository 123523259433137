<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('redeem_reward.head_history') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ $t('redeem_reward.head_history_list') }} </v-card-title>

        <v-card-actions>
          <v-row no-gutters class="d-flex align-center justify-end">
          </v-row>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="value"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.createdat`]="{ item }">
              <div>{{ formatDate(item.createdat) }}</div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn color="success" class=" white--text text-capitalize" style="margin-right: 5px;"
                @click="uกdateRedeemReward(item, 'success')" v-if="item.status == 'pending'">
                Success
              </v-btn>
              <v-btn color="#FF0000" class="white--text text-capitalize" @click="uกdateRedeemReward(item, 'cancel')"
                v-if="item.status == 'pending'">
                Cancel
              </v-btn>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import TextInput from '@/components/input/TextInput.vue'
import store from '@/store'

export default {
  components: {
    DatePickerInput,
    TextInput,
  },

  data() {
    return {
      search: '',
      loading: false,
      canCreate: false,
      rules: [
        value => !!value || this.$t('alert_list.list1'),
        value => (!!value && /^\d+$/.test(value)) || this.$t('alert_list.list2'),
      ],
      headers: [
        {
          text: this.$t('redeem_reward.history_header1'),
          value: 'phone',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.history_header2'),
          value: 'name',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.history_header3'),
          value: 'rewardName',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.history_header4'),
          value: 'status',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.history_header5'),
          value: 'detail',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.history_header6'),
          value: 'createdat',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('redeem_reward.history_header7'),
          value: 'action',
          width: '170px',
          align: 'center',
          sortable: false,
        },
      ],
      value: [],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      breadcrumbsItems: [
        {
          text: this.$t('redeem_reward.head'),
          disabled: false,
          href: '/redeemreward',
        },
        {
          text: this.$t('redeem_reward.head_history'),
          disabled: true,
          href: '',
        },
      ],
      formData: {
        id: 0,
        detail: '',
        status: '',
      },
    }
  },
  async created() {
    // this.addLogPage()
    this.getPermissionsEdit()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          const result = await this.getRedeemRewardHistoryData(newValue.page, newValue.itemsPerPage)
          this.value = [];
          result.data.forEach(element => {
            if (element) {
              this.value.push({
                id: element.id,
                phone: element.member?.phone || '-',
                name: element.member?.name || '',
                rewardName: element.reward?.name || '',
                status: element?.status || '',
                detail: element?.detail || '',
                createdat: element.createdAt,
                action: true,
              })
            }
          })
          this.pagination.totalItems = result.count
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'Redeem Reward',
              url: window.location.href,
              detail: 'ระบบ Redeem Reward',
              admin: userSystem.name ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getRedeemRewardHistoryData(page, row) {
      try {
        return await this.$store.dispatch('getRedeemRewardHistory', `page=${page}&rows=${row}`);
      } catch (e) { console.log("Error " + e); }
    },

    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'redeemReward')
      if (chkEdit && chkEdit?.length > 0) {
        this.canCreate = true
      }
    },
    // async updateStatus(item) {
    //   this.$swal
    //     .fire({
    //       icon: 'warning',
    //       title: this.$t("redeem_reward.alert_update_confirm1"),
    //       text: this.$t("redeem_reward.alert_update_confirm2") + " '" + item.name + "' > " + ((item.status == "true") ? this.$t("redeem_reward.alert_update_disabled") : this.$t("redeem_reward.alert_update_enabled")),
    //       showCancelButton: true,
    //       confirmButtonText: this.$t('confirm'),
    //       cancelButtonText: this.$t('cancel'),
    //     })
    //     .then(async action => {
    //       if (action.isConfirmed) {
    //         item.status = (item.status == "true") ? "false" : "true";
    //         await this.$store.dispatch('updateRedeemRewardStatus', item);
    //       }
    //     })
    // },
    async uกdateRedeemReward(item, status) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t("redeem_reward.alert_history_confirm1"),
          text: this.$t("redeem_reward.alert_history_confirm2") + " '" + item.name + "' " + status,
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        })
        .then(async action => {
          if (action.isConfirmed) {
            this.formData.id = parseInt(item.id);
            this.formData.detail = "";
            this.formData.status = status;
            console.log("updateRedeemRewardHistoryStatus " + this.formData)
            await this.$store.dispatch('updateRedeemRewardHistoryStatus', this.formData);
            this.$router.go()
          }
        })
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
    linkBack() {
      this.$router.push({ name: 'redeemreward' })
    },
  },
}
</script>
